import React, { memo } from 'react'
import { Helmet } from 'react-helmet';

const HomeBG = ({ className, 
                  alt, 
                  fetchPriority = "low", 
                  windowSize,
                  imgMobile,
                  img1000,
                  img1300,
                  img1500
                }) => {
  
  let imgSrc;
  if(windowSize < 600) {
    imgSrc = imgMobile
  } else if(windowSize < 1300) {
    imgSrc = img1000
  } else if(windowSize < 1500) {
    imgSrc = img1300
  } else {
    imgSrc = img1500
  }
  
  return (
    <>
      <img
        //src= {imgSrc}
        className= { className }
        alt= { alt }
        fetchPriority= { fetchPriority }
      />
      {/* <Helmet>
        <link rel="preload" fetchpriority="high" as="image" href={imgSrc} type="image/webp" />
      </Helmet> */}
    </>
  )
}

export default memo(HomeBG);